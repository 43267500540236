import 'isomorphic-fetch';

export const FETCH_CATEGORIES_BEGIN = 'FETCH_CATEGORY_BEGIN';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const TOGGLE_CATEGORY_FILTER = 'TOGGLE_CATEGORY_FILTER';

export const toggleCategoryFilter = id => ({
  type: TOGGLE_CATEGORY_FILTER,
  id,
});

export const fetchCategoryBegin = () => ({
  type: FETCH_CATEGORIES_BEGIN,
});

export const fetchCategorySuccess = categories => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: { categories },
});

export const fetchCategoryFailure = error => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload: { error },
});

export function fetchCategories() {
  return dispatch => {
    dispatch(fetchCategoryBegin());

    return fetch(`/${I18n.locale}/poi_category`)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => dispatch(fetchCategorySuccess(json)))
      .catch(error => dispatch(fetchCategoryFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
