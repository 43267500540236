import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import 'core-js/features/url-search-params';

import styled from 'styled-components';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

export const Figure = styled.figure`
  font-size: 16px;
  color: #555;
  margin: 0;
  background-color: white;
  figcaption {
    padding-top: 2px;
  }
`;

const getInitialSlide = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return parseInt(searchParams.get('index')) || 0;
};

export const Image = ({ image, index }) => {
  let history = useHistory();
  let setIndex = index => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('index', index);
    history.replace(window.location.pathname + '?' + searchParams.toString());
  };

  const clickHandler = src => {
    return () => {
      setIndex(index);
      history.push(`/${I18n.locale}/lightbox?src=${src}`);
    };
  };

  return (
    <img
      alt={image.description}
      src={image.image_link}
      onClick={clickHandler(image.large_image_link)}
    ></img>
  );
};

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    images: PropTypes.array.isRequired,
  };

  render() {
    const images = this.props.images;
    if (!images || images.length == 0) return null;

    const settings = {
      adaptiveHeight: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      initialSlide: getInitialSlide(),
    };
    return (
      <Slider {...settings}>
        {images.map((image, key) => (
          <Figure key={key}>
            <Image image={image} index={key}></Image>
            <figcaption>{image.title}</figcaption>
          </Figure>
        ))}
      </Slider>
    );
  }
}

export default ImageCarousel;
