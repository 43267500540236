export const CENTER_TO_LOCATION = 'CENTER_TO_LOCATION';
export const SET_USER_POSITION = 'SET_USER_POSITION';
export const PAN_TO_USER_POSITION_BEGIN = 'PAN_TO_USER_POSITION_BEGIN';
export const PAN_TO_USER_POSITION_DONE = 'PAN_TO_USER_POSITION_DONE';
export const POSITIONING_ERROR = 'POSITIONING_ERROR';

export function watchPosition(store) {
  let initialized = false;
  if ('geolocation' in navigator) {
    navigator.geolocation.watchPosition(
      position => {
        store.dispatch({
          type: SET_USER_POSITION,
          userPosition: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        // Initially pan to position once.
        if (!initialized) {
          initialized = true;
          store.dispatch({
            type: CENTER_TO_LOCATION,
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          store.dispatch({ type: PAN_TO_USER_POSITION_BEGIN });
        }
      },
      error => store.dispatch({ type: POSITIONING_ERROR, error: error })
    );
  } else {
    store.dispatch({
      type: POSITIONING_ERROR,
      error: 'no geolocation in navigator',
    });
  }
}

export function centerToUserLocation() {
  return dispatch => {
    const geolocation = navigator.geolocation;
    geolocation.getCurrentPosition(
      position => {
        dispatch({
          type: CENTER_TO_LOCATION,
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        dispatch({
          type: SET_USER_POSITION,
          userPosition: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        dispatch({ type: PAN_TO_USER_POSITION_BEGIN });
      },
      error => {
        dispatch({ type: POSITIONING_ERROR, error: error });
      }
    );
  };
}

export const centerToLocation = position => ({
  type: CENTER_TO_LOCATION,
  position: position,
});

export const panToUserPositionDone = () => ({
  type: PAN_TO_USER_POSITION_DONE,
});
