import React, { Component } from 'react';
import { Overlay, List, ListContainer, CloseButton } from './styled';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleCategoryFilter } from '../actions/categories';
import { toggleOptions } from '../actions/options';
import styled, { css } from 'styled-components';
import icon from '../../assets/images/close.png';

const HiddenInput = styled.input`
  display: none;
`;

const ListImg = styled.img`
  padding-right: 2px;
  vertical-align: middle;
  width: 26px;

  ${props =>
    props.isDisabled &&
    css`
      filter: grayscale(100%);
      opacity: 0.6;
    `}
`;

const ListLabel = styled.label`
  white-space: pre;

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.6;
    `}
`;

const CloseMenu = styled.div`
  z-index: 2;
  position: absolute;
  top: -15px;
  right: -15px;
`;

class OptionsOverlay extends Component {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    onCategoryClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
  };

  render() {
    const { categories, filter } = this.props.categories;
    return (
      <Overlay>
        <ListContainer>
          <List>
            <div style={{ textAlign: 'right' }}>
              <CloseMenu>
                <CloseButton
                  type="image"
                  src={icon}
                  onClick={this.props.onCloseClick}
                />
              </CloseMenu>
            </div>

            {categories &&
              categories.map(category => {
                const isDisabled = !filter.find(id => id === category.id);
                return (
                  <li key={category.id}>
                    <HiddenInput
                      id={category.id}
                      type="checkbox"
                      onChange={() => this.props.onCategoryClick(category.id)}
                      defaultChecked={isDisabled}
                    />
                    <ListLabel htmlFor={category.id} isDisabled={isDisabled}>
                      <ListImg
                        src={category.image_link}
                        isDisabled={isDisabled}
                      />
                      {category.title}
                    </ListLabel>
                  </li>
                );
              })}
          </List>
        </ListContainer>
      </Overlay>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
});

const mapDispatchToProps = dispatch => {
  return {
    onCategoryClick: id => {
      dispatch(toggleCategoryFilter(id));
    },
    onCloseClick: () => dispatch(toggleOptions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionsOverlay);
