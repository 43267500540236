import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledInfoMenu } from './styled';
import icon from '../../assets/images/info.png';
import { connect } from 'react-redux';
import history from './history';

class InfoOverlay extends Component {
  handleClick = () => {
    if (this.props.poiShown)
      history.push(`/${I18n.locale}/poi/${this.props.place.id}/info`);
    else history.push(`/${I18n.locale}/info`);
  };

  render() {
    if (!this.props.introShown && !this.props.contentShown) {
      return (
        <StyledInfoMenu>
          <StyledButton type="image" src={icon} onClick={this.handleClick} />
        </StyledInfoMenu>
      );
    }
    return null;
  }
}

InfoOverlay.propTypes = {
  dispatch: PropTypes.func.isRequired,
  introShown: PropTypes.bool.isRequired,
  contentShown: PropTypes.bool.isRequired,
  poiShown: PropTypes.bool.isRequired,
  place: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    introShown: state.places.introShown,
    contentShown: state.places.contentShown,
    poiShown: state.places.poiShown,
    place: state.places.place,
  };
};

export default connect(mapStateToProps)(InfoOverlay);
