export const POI_OVERLAY_SHOW = 'POI_OVERLAY_SHOW';
export const POI_OVERLAY_HIDE = 'POI_OVERLAY_HIDE';

export const showPoiOverlay = place => ({
  type: POI_OVERLAY_SHOW,
  place,
});

export const hidePoiOverlay = () => ({
  type: POI_OVERLAY_HIDE,
});
