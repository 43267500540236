import {
  CENTER_TO_LOCATION,
  SET_USER_POSITION,
  PAN_TO_USER_POSITION_BEGIN,
  PAN_TO_USER_POSITION_DONE,
  POSITIONING_ERROR,
} from '../actions/location';

const initialState = {
  position: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CENTER_TO_LOCATION:
      return {
        ...state,
        position: action.position,
      };

    case SET_USER_POSITION:
      return {
        ...state,
        userPosition: action.userPosition,
      };

    case PAN_TO_USER_POSITION_BEGIN:
      return {
        ...state,
        shouldPanToUserPosition: true,
      };

    case PAN_TO_USER_POSITION_DONE:
      return {
        ...state,
        shouldPanToUserPosition: false,
      };

    case POSITIONING_ERROR:
      console.log(action.error);
      window.alert(
        'Die Kultourrouten können nicht auf Ihre Position zugreifen.'
      );
      return state;
    default:
      return state;
  }
}
