import { OPTIONS_TOGGLE } from '../actions/options';

const initialState = {
  visible: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPTIONS_TOGGLE:
      return {
        ...state,
        visible: !state.visible,
      };

    default:
      return state;
  }
}
