import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const MyLocation = () => (
  // https://github.com/google-map-react/google-map-react/issues/630#issuecomment-472828784
  <div style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
    <FontAwesomeIcon icon={faMapMarkerAlt} size="5x" color="#010F48" />
  </div>
);

export default MyLocation;
