import {
  FETCH_PLACES_BEGIN,
  FETCH_PLACES_SUCCESS,
  FETCH_PLACES_FAILURE,
} from '../actions/places';

import { POI_OVERLAY_SHOW, POI_OVERLAY_HIDE } from '../actions/poiOverlay';

import {
  INFO_OVERLAY_SHOW,
  INFO_OVERLAY_HIDE,
  INTRO_OVERLAY_SHOW,
  INTRO_OVERLAY_HIDE,
  CONTENT_OVERLAY_SHOW,
  CONTENT_OVERLAY_HIDE,
} from '../actions/infoOverlay';

const initState = {
  places: [],
  place: null,
  poiShown: false,
  infoShown: false,
  introShown: false,
  contentShown: false,
};

const placesReducers = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PLACES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PLACES_SUCCESS:
      var place = false;
      var matches = window.location.pathname.match(/^\/pois\/(\d+)$/);
      if (matches && matches.length == 2) {
        const id = parseInt(matches[1]);
        action.payload.places.forEach(p => {
          if (p.id === id) {
            place = p;
          }
        });
      }
      if (place) {
        return {
          ...state,
          loading: false,
          place: place,
          poiShown: true,
          places: action.payload.places,
          infoShown: true,
        };
      }
      return {
        ...state,
        loading: false,
        places: action.payload.places,
      };

    case FETCH_PLACES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error, // TODO
        places: [],
      };
    case POI_OVERLAY_SHOW:
      return {
        ...state,
        poiShown: true,
        place: action.place,
      };

    case POI_OVERLAY_HIDE:
      return {
        ...state,
        poiShown: false,
        place: null,
      };
    case INFO_OVERLAY_SHOW:
      return {
        ...state,
        infoShown: true,
      };
    case INFO_OVERLAY_HIDE:
      return {
        ...state,
        infoShown: false,
      };
    case INTRO_OVERLAY_SHOW:
      return {
        ...state,
        introShown: true,
      };
    case INTRO_OVERLAY_HIDE:
      return {
        ...state,
        introShown: false,
      };
    case CONTENT_OVERLAY_SHOW:
      return {
        ...state,
        contentShown: true,
      };
    case CONTENT_OVERLAY_HIDE:
      return {
        ...state,
        contentShown: false,
      };
    default:
      return state;
  }
};

export default placesReducers;
