import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from './styled';
import { centerToUserLocation } from '../actions/location';
import icon from '../../assets/images/karte-zentrieren.png';

class CenterButton extends Component {
  handleClick = () => {
    this.props.dispatch(centerToUserLocation());
  };

  render() {
    return <StyledButton type="image" src={icon} onClick={this.handleClick} />;
  }
}

CenterButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps)(CenterButton);
