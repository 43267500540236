import React from 'react';
import { StyledImprintLink } from './styled';

const ImprintLink = () => (
  <StyledImprintLink href={`/${I18n.locale}/pages/imprint`}>
    {I18n.t('imprint')}
  </StyledImprintLink>
);

export default ImprintLink;
