import React, { Component } from 'react';
import Switch from 'react-switch';
import { StyledLanguageSwitch } from './styled';

class LanguageSwitch extends Component {
  state = { checked: I18n.locale === 'en' };

  handleChange = checked => {
    const loc = checked ? 'en' : 'de';
    window.location.replace(`/${loc}`);
  };

  render() {
    return (
      <StyledLanguageSwitch>
        <label>
          <Switch
            onChange={this.handleChange}
            checked={this.state.checked}
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 15,
                  color: '#fff',
                }}
              >
                EN
              </div>
            }
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 15,
                  color: '#fff',
                }}
              >
                DE
              </div>
            }
            offColor="#010F48"
            onColor="#010F48"
          />
        </label>
      </StyledLanguageSwitch>
    );
  }
}

export default LanguageSwitch;
