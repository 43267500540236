import {
  FETCH_CATEGORIES_BEGIN,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  TOGGLE_CATEGORY_FILTER,
} from '../actions/categories';

const initialState = {
  categories: [],
  loading: false,
  error: null,
  filter: [],
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        filter: action.payload.categories.map(category => category.id),
      };

    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error, // TODO
      };

    case TOGGLE_CATEGORY_FILTER:
      return {
        ...state,
        filter: state.filter.find(id => id === action.id)
          ? state.filter.filter(id => id !== action.id)
          : state.filter.concat(action.id),
      };

    default:
      return state;
  }
}
