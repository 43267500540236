import 'isomorphic-fetch';

export const FETCH_PLACES_BEGIN = 'FETCH_PLACES_BEGIN';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_FAILURE = 'FETCH_PLACES_FAILURE';

export const fetchPlacesBegin = () => ({
  type: FETCH_PLACES_BEGIN,
});

export const fetchPlacesSuccess = places => ({
  type: FETCH_PLACES_SUCCESS,
  payload: { places },
});

export const fetchPlacesFailure = error => ({
  type: FETCH_PLACES_FAILURE,
  payload: { error },
});

export function fetchPlaces() {
  return dispatch => {
    dispatch(fetchPlacesBegin());

    return (
      fetch(`/${I18n.locale}/poi`)
        //.then(handleErrors)
        .then(res => res.json())
        .then(json => dispatch(fetchPlacesSuccess(json)))
        .catch(error => dispatch(fetchPlacesFailure(error)))
    );
  };
}
