import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Map from './components/map';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import store from './store';
import history from './components/history';
import { watchPosition } from './actions/location';

const Home = () => <Map />;

const wrapper = document.createElement('div');
wrapper.className = 'map-container';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path={`/${I18n.locale}`}>
            <Home />
          </Route>
        </Switch>
      </Router>
    </Provider>,
    document.body.appendChild(wrapper)
  );
  watchPosition(store);
});
