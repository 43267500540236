import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideIntroOverlay } from '../actions/infoOverlay';
import { centerToLocation } from '../actions/location';
import history from './history';

class Marker extends Component {
  handleClick = () => {
    if (this.props.contentShown && !this.props.poiShown)
      history.push(`/${I18n.locale}/info/poi/${this.props.place.id}`);
    else {
      if (this.props.introShown) {
        this.props.dispatch(hideIntroOverlay());
      }
      history.push(`/${I18n.locale}/poi/${this.props.place.id}`);
    }

    this.props.dispatch(
      centerToLocation({
        lat: this.props.place.lat,
        lng: this.props.place.long,
      })
    );
  };

  render() {
    const { categories } = this.props;
    let category;

    if (categories.categories) {
      category = categories.categories.find(
        cat => cat.id === this.props.category
      );
    }

    if (category == null) {
      return <div></div>;
    }

    return (
      // https://github.com/google-map-react/google-map-react/issues/630#issuecomment-472828784
      <div style={{ position: 'absolute', transform: 'translate(0%, -100%)' }}>
        <input
          type="image"
          src={category.image_link}
          width="36"
          height="36"
          onClick={this.handleClick}
        />
      </div>
    );
  }
}

Marker.propTypes = {
  categories: PropTypes.object.isRequired,
  category: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  place: PropTypes.object.isRequired,
  introShown: PropTypes.bool.isRequired,
  contentShown: PropTypes.bool.isRequired,
  poiShown: PropTypes.bool.isRequired,
};

const mapStateToProps = function(state) {
  return {
    categories: state.categories,
    introShown: state.places.introShown,
    contentShown: state.places.contentShown,
    poiShown: state.places.poiShown,
  };
};

export default connect(mapStateToProps)(Marker);
