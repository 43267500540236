import styled from 'styled-components';

export const StyledImprintLink = styled.a`
  position: fixed;
  left: 25px;
  bottom: 60px;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  border-radius: 7px;
  z-index: 0;
  color: #010f48;
`;

export const StyledPrivacyLink = styled.a`
  position: fixed;
  left: 25px;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  border-radius: 7px;
  z-index: 0;
  color: #010f48;
`;

export const StyledLanguageSwitch = styled.div`
  position: fixed;
  right: 6px;
  top: 20px;
  background-color: #fff;
  padding: 3px;
  border-radius: 17px;
  z-index: 0;
  color: #010f48;
`;

export const StyledRoutesMenu = styled.div`
  position: fixed;
  right: 75px;
  bottom: 20px;
  > * {
    margin-left: 2px;
  }
`;

export const StyledInfoMenu = styled.div`
  position: fixed;
  left: 25px;
  top: 20px;
  z-index: 3;
`;

export const StyledButton = styled.input`
  outline: none;
  width: 50px;
  height: 50px;
`;

export const ListContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 4px 2px 4px 0;

  li {
    padding: 10px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  right: 75px;
  bottom: 80px;

  width: 100px;
  height: 100px;
`;

export const StyledIntro = styled.section`
  position: absolute;
  top: 25px;
  left: 100px;
`;

export const StyledContent = styled.section`
  position: absolute;
  top: 25px;
  left: 100px;
`;

export const CloseButton = styled.input`
  pointer-events: auto;
  height: 36px;
  width: 36px;
`;
