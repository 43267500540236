import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import icon from '../../assets/images/close.png';
import {
  showIntroOverlay,
  hideIntroOverlay,
  showContentOverlay,
  hideContentOverlay,
} from '../actions/infoOverlay';
import { showPoiOverlay, hidePoiOverlay } from '../actions/poiOverlay';
import { connect } from 'react-redux';
import history from './history';
import ImageCarousel from './imageCarousel';
import { CloseButton } from './styled';

const Area = styled.div`
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  height: 96%;
  width: 100%;
  justify-content: center;
  padding: 24px 0;
  pointer-events: none;
  z-index: 1;
`;

const Section = styled.div`
  display: flex;
  flex: 0 1 384px;
  text-align: right;
  flex-direction: column;
  width: 70vw;
  max-width: 384px;
`;

const CloseMenu = styled.div`
  flex: 0 0 48px;
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1 1 0;
  background-color: white;
  padding: 24px;
  margin: 12px;
  margin-top: -36px;
  text-align: left;
  pointer-events: auto;

  img {
    width: 100%;
  }
`;

const StyledCarousel = styled.div`
  margin-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
`;

const PoiDescription = styled.div`
  white-space: pre-line;
`;

const markupContainer = document.getElementById('info-overlay');

const IntroSection = ({ dispatch, placeId, showPoi }) => (
  <Section>
    <CloseMenu>
      <CloseButton
        type="image"
        src={icon}
        onClick={() => {
          dispatch(hideIntroOverlay());
          if (showPoi) history.push(`/${I18n.locale}/poi/${placeId}`);
          else history.push(`/${I18n.locale}`);
        }}
      />
    </CloseMenu>
    <Content
      dangerouslySetInnerHTML={{
        __html:
          markupContainer.dataset.intro ||
          markupContainer.getAttribute('data-intro'),
      }}
    ></Content>
  </Section>
);

IntroSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  placeId: PropTypes.number.isRequired,
  showPoi: PropTypes.bool.isRequired,
};

const ContentSection = ({ dispatch, place, showPoi }) => (
  <Section>
    <CloseMenu>
      <CloseButton
        type="image"
        src={icon}
        onClick={() => {
          dispatch(hideContentOverlay());
          if (showPoi && place) history.push(`/${I18n.locale}/poi/${place.id}`);
          else history.push(`/${I18n.locale}`);
        }}
      />
    </CloseMenu>
    <Content
      dangerouslySetInnerHTML={{
        __html:
          markupContainer.dataset.content ||
          markupContainer.getAttribute('data-content'),
      }}
    ></Content>
  </Section>
);

ContentSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  place: PropTypes.object,
  showPoi: PropTypes.bool.isRequired,
};

const PoiSection = ({ dispatch, place, categories, showContent }) => {
  const category = categories.find(cat => cat.id === place.poi_category_id) || {
    title: I18n.t('loading'),
  };
  return (
    <Section>
      <CloseMenu>
        <CloseButton
          type="image"
          src={icon}
          onClick={() => {
            dispatch(hidePoiOverlay());
            if (showContent) {
              history.push(`/${I18n.locale}/info`);
            } else {
              dispatch(hideIntroOverlay());
              history.push(`/${I18n.locale}`);
            }
          }}
        />
      </CloseMenu>
      <Content>
        <h2>{place.title}</h2>
        <p>
          {I18n.t('category') + ': '}
          {category.title}
        </p>
        <StyledCarousel>
          <ImageCarousel images={place.poi_images}></ImageCarousel>
        </StyledCarousel>
        <PoiDescription>{place.description}</PoiDescription>
      </Content>
    </Section>
  );
};

PoiSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  place: PropTypes.object,
  categories: PropTypes.array,
  showContent: PropTypes.bool.isRequired,
};

const ContentArea = ({
  poiShown,
  dispatch,
  categories,
  places,
  showPoi,
  showIntro,
  showContent,
  introShown,
  contentShown,
  match: {
    params: { id },
  },
}) => {
  if (!showIntro && !showContent && !showPoi) return null;

  let place;

  if (showPoi) {
    if (places.length) {
      place = places.find(p => p.id == parseInt(id));
      if (!poiShown) dispatch(showPoiOverlay(place));
    }
    if (!place) {
      return null;
    }
  }

  if (showIntro && !introShown) dispatch(showIntroOverlay());
  if (showContent && !contentShown) dispatch(showContentOverlay());

  return (
    <Area>
      {showContent && (
        <ContentSection
          dispatch={dispatch}
          place={place}
          showPoi={showPoi}
          showIntro={showIntro}
          showContent={showContent}
        />
      )}
      {showIntro && (
        <IntroSection
          dispatch={dispatch}
          placeId={id}
          showPoi={showPoi}
          showIntro={showIntro}
          showContent={showContent}
        />
      )}
      {showPoi && place && categories && (
        <PoiSection
          dispatch={dispatch}
          place={place}
          categories={categories}
          showPoi={showPoi}
          showIntro={showIntro}
          showContent={showContent}
        />
      )}
    </Area>
  );
};

ContentArea.propTypes = {
  places: PropTypes.array,
  categories: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  poiShown: PropTypes.bool.isRequired,
  introShown: PropTypes.bool.isRequired,
  contentShown: PropTypes.bool.isRequired,
  showPoi: PropTypes.bool.isRequired,
  showIntro: PropTypes.bool.isRequired,
  showContent: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    places: state.places.places,
    categories: state.categories.categories,
    poiShown: state.places.poiShown,
    introShown: state.places.introShown,
    contentShown: state.places.contentShown,
  };
};

export default connect(mapStateToProps)(ContentArea);
