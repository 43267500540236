import React, { Component } from 'react';
import { StyledRoutesMenu } from './styled';
import CenterButton from './centerButton';
import FilterButton from './filterButton';

class Menu extends Component {
  render() {
    return (
      <StyledRoutesMenu>
        <CenterButton />
        <FilterButton />
      </StyledRoutesMenu>
    );
  }
}

export default Menu;
