export const INFO_OVERLAY_SHOW = 'INFO_OVERLAY_SHOW';
export const INFO_OVERLAY_HIDE = 'INFO_OVERLAY_HIDE';
export const INTRO_OVERLAY_SHOW = 'INTRO_OVERLAY_SHOW';
export const INTRO_OVERLAY_HIDE = 'INTRO_OVERLAY_HIDE';
export const CONTENT_OVERLAY_SHOW = 'CONTENT_OVERLAY_SHOW';
export const CONTENT_OVERLAY_HIDE = 'CONTENT_OVERLAY_HIDE';

export const showInfoOverlay = () => ({
  type: INFO_OVERLAY_SHOW,
});

export const hideInfoOverlay = () => ({
  type: INFO_OVERLAY_HIDE,
});

export const showIntroOverlay = () => ({
  type: INTRO_OVERLAY_SHOW,
});

export const hideIntroOverlay = () => ({
  type: INTRO_OVERLAY_HIDE,
});

export const showContentOverlay = () => ({
  type: CONTENT_OVERLAY_SHOW,
});

export const hideContentOverlay = () => ({
  type: CONTENT_OVERLAY_HIDE,
});
