import { combineReducers } from 'redux';
import places from './places';
import categories from './categories';
import location from './location';
import options from './options';

export default combineReducers({
  places,
  categories,
  location,
  options,
});
