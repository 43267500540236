import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from './styled';
import { toggleOptions } from '../actions/options';
import icon from '../../assets/images/optionen.png';

class FilterButton extends Component {
  handleClick = () => this.props.dispatch(toggleOptions());

  render() {
    return <StyledButton type="image" src={icon} onClick={this.handleClick} />;
  }
}

FilterButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps)(FilterButton);
