import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import 'core-js/features/url-search-params';
import { connect } from 'react-redux';

const LightboxArea = styled.div`
  /* align-items: center; */
  background-color: black;
  /* display: flex; */
  height: 100%;
  /* justify-content: center; */
  left: 0;
  margin: 0;
  overflow: scroll;
  padding: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 4;

  img {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  p {
    color: white;
    flex: 1 1 100%;
  }

  a {
    color: white;
    font-family: slick;
    font-size: 2em;
    cursor: pointer;
    display: inline-block;
    margin: 16px;
  }
`;

const Navigation = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.33);
  width: 100%;
`;

const LinkBefore = styled.a`
  left: 0;
`;

const LinkAfter = styled.a`
  right: 0;
`;

const Lightbox = ({ location, places }) => {
  const src = new URLSearchParams(location.search).get('src');
  let history = useHistory();

  const goBack = (poiID, index) => {
    if (index !== NaN) {
      history.push(`/${I18n.locale}/poi/${poiID}?index=${index}`);
    } else {
      history.push(`/${I18n.locale}`);
    }
  };

  if (!places || !places.places) {
    return (
      <LightboxArea className="lightbox" onClick={goBack}>
        <img src={src} />
      </LightboxArea>
    );
  }

  const clickHandler = src => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      history.push(`/${I18n.locale}/lightbox?src=${src}`);
    };
  };

  let img = null;
  let imgBefore = null;
  let imgAfter = null;
  let index = NaN;
  let placeID = null;
  let finalMaxIndex = 0;

  places.places.forEach(place => {
    const maxIndex = place.poi_images.length - 1;
    place.poi_images.forEach((poi_image, i) => {
      if (poi_image.large_image_link.replace(' ', '%20') === src.replace(' ', '%20')) {
        index = i;
        placeID = place.id;
        img = poi_image;
        imgBefore = place.poi_images[i - 1 < 0 ? maxIndex : i - 1];
        imgAfter = place.poi_images[i + 1 > maxIndex ? 0 : i + 1];
        finalMaxIndex = maxIndex;
      }
    });
  });

  return (
    <LightboxArea className="lightbox" onClick={() => goBack(placeID, index)}>
      <img src={src} />
      {img ? (
        finalMaxIndex > 0 ? (
          <Navigation>
            <LinkBefore onClick={clickHandler(imgBefore.large_image_link)}>
              ←
            </LinkBefore>
            <p>{img.title}</p>
            <LinkAfter onClick={clickHandler(imgAfter.large_image_link)}>
              →
            </LinkAfter>
          </Navigation>
        ) : (
          <Navigation>
            <p>{img.title}</p>
          </Navigation>
        )
      ) : (
        <></>
      )}
    </LightboxArea>
  );
};

const mapStateToProps = state => ({
  places: state.places,
});

export default connect(mapStateToProps)(Lightbox);
